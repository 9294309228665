<template>
  <div></div>
  <!-- <div class="login">
    <div class="login_box">
      <p class="login_title">欢迎登录DataMonitor.Shop</p>
      <div class="login_box_win">
        <img src="../../assets/image/logo2.png" class="login_logo" />
        <div class="login_item">
          <img src="../../assets/image/zhnaghao.png" class="login_item_icon" />
          <input
            type="text"
            v-model="name"
            placeholder="请输入用户名"
            class="login_item_input"
            v-on:keyup.enter="handleSubmit"
          />
        </div>
        <div class="login_item">
          <img src="../../assets/image/mima.png" class="login_item_icon" />
          <input
            type="password"
            v-model="password"
            placeholder="请输入密码"
            class="login_item_input"
            v-on:keyup.enter="handleSubmit"
          />
        </div>
        <el-button type="primary" class="submit" @click="handleSubmit" :loading="loading"
          >登录</el-button
        >
        <div style="margin-top: 10px" class="ckNam">
          <el-checkbox v-model="checked" @change="memory">记住登录名和密码</el-checkbox>
          <el-link type="primary" href="./loginForgot">找回密码</el-link>
        </div>
      </div>
    </div>
    <el-dialog
      title="请选择进入的品牌"
      :visible.sync="loginBrand"
      width="400px"
      @close="handleClose"
    >
      <el-select v-model="brand" placeholder="请选择品牌" style="width: 100%">
        <el-option
          v-for="item in brandData"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="brandFirm">
        <el-button type="primary" size="small" @click="handleFirm">确定</el-button>
      </div>
    </el-dialog>
    <div class="footer">
      <p class="footer_top">Version 1.3 2023-07-28</p>
      <p class="footer-btn">
        © 2021 DataMonitor.Shop
        <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备16027466号-5</a>
      </p>
    </div>
  </div> -->
</template>

<script>
export default {
  data() {
    return {
      name: "",
      password: "",
      brand: "",
      loading: false,
      loginBrand: false, //品牌选择
      brandData: [],
      checked: false, //是否记住登录账号密码
    };
  },
  created() {
    window.location.href='http://cas.datamonitor.shop/login?returnUrl=http://www2.datamonitor.shop/'
    

    let userName = localStorage.getItem("userName");
    if (userName != null || userName != undefined) {
      this.checked = true;
      this.name = JSON.parse(userName).name;
      this.password = JSON.parse(userName).password;
    }
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    this.$store.commit("loginSuccess");
  },
  methods: {
    // 记住账号
    memory(boot) {
      this.checked = boot;
    },
    handleSubmit() {
      if (this.name == "") {
        this.$message.error("请填写账号");
        return false;
      }
      if (this.password == "") {
        this.$message.error("请填写密码");
        return false;
      }
      let data = {
        name: this.name,
        password: this.password,
        brand: this.brand,
      };
      this.loading = true;
      this.$axios.post("/api/v6/Authentication", data).then((res) => {
        this.loading = false;
        switch (res.data.status) {
          case 0:
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.$store.commit("login", res.data.data);
            localStorage.setItem("token", res.data.data);
            this.userInfo();
            break;
          case -1:
            this.$message({
              message: res.data.message,
              type: "error",
            });
            break;
          case 301:
            if (res.data.data.length == 1) {
              this.brand = res.data.data[0];
              this.handleSubmit();
            } else {
              for (let i in res.data.data) {
                this.brandData.push({ value: res.data.data[i], label: res.data.data[i] });
              }
              this.loginBrand = true;
            }
            break;
          default:
            this.$message({
              message: res.data.message,
              type: "error",
            });
        }
      });
    },
    userInfo() {
      this.$axios.get("/as/api/v6/User").then((res) => {
        this.$store.commit("user", JSON.stringify(res.data.data));
        localStorage.setItem("user", JSON.stringify(res.data.data));
        if (this.checked) {
          let userName = {
            name: this.name,
            password: this.password,
          };
          localStorage.setItem("userName", JSON.stringify(userName));
        } else {
          localStorage.removeItem("userName");
        }
        this.$router.push({
          path: "/",
        });
      });
    },
    // 品牌选择确认
    handleFirm() {
      if (this.brand == "") {
        this.$message({
          message: "请选择品牌",
          type: "error",
        });
      } else {
        // 关闭弹框
        this.loginBrand = false;
        // 重新登录
        this.handleSubmit();
      }
    },
    // 窗口关闭
    handleClose() {
      this.loginBrand = false;
      this.brandData = [];
      this.brand = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/image/background.png");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-color: #e6f7ff;
  .login_box {
    width: 415px;
    position: fixed;
    top: 50%;
    margin-top: -280px;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1400px) {
      width: 350px;
      margin-top: -240px;
    }
    .login_title {
      font-size: 32px;
      color: #3978f7;
      margin-bottom: 28px;
      @media screen and (max-width: 1400px) {
        font-size: 24px;
        margin-bottom: 20px;
      }
    }
    .login_box_win {
      width: 400px;
      height: 480px;
      background-color: #ffffff;
      box-shadow: 0px 4px 15px 0px rgba(180, 208, 249, 0.4);
      border-radius: 4px;
      padding: 0 50px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      transition: all 0.3s;
      @media screen and (max-width: 1400px) {
        width: 300px;
        height: 360px;
        padding: 0 30px;
      }
      p {
        font-size: 32px;
        color: #3978f7;
        text-align: center;
        margin-top: 53px;
        margin-bottom: 50px;
        @media screen and (max-width: 1400px) {
          margin-top: 33px;
          margin-bottom: 30px;
        }
      }
      .login_logo {
        width: 96%;
        height: auto;
        margin: 53px auto 50px;
        @media screen and (max-width: 1400px) {
          margin: 37px auto 44px;
        }
      }
      .login_item {
        width: 300px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        display: flex;
        padding: 0 12px;
        box-sizing: border-box;
        align-items: center;
        margin-bottom: 24px;
        transition: all 0.3s;
        @media screen and (max-width: 1400px) {
          width: 100%;
        }
        .login_item_icon {
          width: 20px;
          height: 20px;
          margin-right: 12px;
        }
        .login_item_input {
          flex: 1;
          height: 100%;
          color: $text_color;
          &:focus {
            outline: none;
          }
        }
      }
      .submit {
        background-color: #3978f7;
        margin-top: 30px;
      }
    }
  }
}
.footer {
  width: 100%;
  position: fixed;
  bottom: 24px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .footer_top {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
  }
  .footer-btn {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 20px;
    a {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
      line-height: 20px;
    }
  }
}
.brandFirm {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .el-button {
    width: 90px;
  }
}
.ckNam {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
